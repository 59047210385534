import { makeStyles } from '../../material';

const bannerContainerBasic = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  justifyContent: 'center' as const,
};
const MainWrapperBasic = {
  alignItems: 'center' as const,
  display: 'flex' as const,
  flexDirection: 'column' as const,
  height: '100%' as const,
  zIndex: 10,
};
const cardContentBasic = {
  alignItems: 'center' as const,
  display: 'flex' as const,
  flexDirection: 'column' as const,
  height: '100%',
  justifyContent: 'center' as const,
  width: '100%',
};

export const useStyles = makeStyles(
  {
    backgroundContainer: {
      height: '100vh',
      position: 'absolute',
      width: '100vw',
      zIndex: 0,
    },
    bannerContainer: bannerContainerBasic,

    cardContent: {
      ...cardContentBasic,
      '&:last-child': {
        paddingBottom: 0,
      },
      padding: 0,
      position: 'relative',
    },
    footerContainer: {
      bottom: 20,
      position: 'absolute',
    },
    mainWrapper: MainWrapperBasic,
  },
  { index: 1 },
);
