import React from 'react';

import { Card, CardContent, Grid } from '../../material';
import { Footer } from '../Footer';
import { SourcesBackround } from './SourcesBackground';
import { useStyles } from './styles';

/**
 * Renders the WelcomeView displaying the background and Outmind logo
 * @param props Props used to render the WelcomeView
 */
export const WelcomeView: React.FC<WelcomeViewProps> = ({ children, withFooter }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.backgroundContainer} container>
      <Grid item md={6} style={{ zIndex: 1 }} xs={12}>
        <Card className={classes.mainWrapper} elevation={0}>
          <CardContent className={classes.cardContent}>
            {children}
            {withFooter ? (
              <div className={classes.footerContainer}>
                <Footer />
              </div>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        md={6}
        style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
        xs={12}
      >
        <div className={classes.bannerContainer}>
          <SourcesBackround />
        </div>
      </Grid>
    </Grid>
  );
};

interface WelcomeViewProps {
  withFooter?: boolean;
}
