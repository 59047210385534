import React from 'react';

import { SpinningSourceStylesProps, useStyles } from './styles';

/**
 * Renders the background circles and the moving sources
 */
export const SpinningSource: React.FC<SpinningSourceProps> = ({ logo, ...styleProps }) => {
  const classes = useStyles(styleProps);

  return (
    <div className={classes.circleContainer}>
      <div className={classes.rotatingCircle}>
        <div className={classes.logoContainer}>
          <img alt="connector-logo" className={classes.logoImage} src={logo} />
        </div>
      </div>
    </div>
  );
};

interface SpinningSourceProps extends SpinningSourceStylesProps {
  logo: string;
}
