import React, { memo } from 'react';

import boxLogo from '../../../assets/connectors/box.png';
import driveLogo from '../../../assets/connectors/drive.png';
import dropboxLogo from '../../../assets/connectors/dropbox.png';
import gmailLogo from '../../../assets/connectors/gmail.png';
import localDiskLogo from '../../../assets/connectors/localdisk.png';
import networkDiskLogo from '../../../assets/connectors/networkdisk.png';
import notionLogo from '../../../assets/connectors/notion.png';
import o365Logo from '../../../assets/connectors/o365.png';
import onedriveLogo from '../../../assets/connectors/onedrive.png';
import outlookLogo from '../../../assets/connectors/outlook.png';
import salesforceLogo from '../../../assets/connectors/salesforce.png';
import sharepointLogo from '../../../assets/connectors/sharepoint.png';
import slackLogo from '../../../assets/connectors/slack.png';
import teamsLogo from '../../../assets/connectors/teams.png';
import trelloLogo from '../../../assets/connectors/trello.png';
import outmindLogo from '../../../assets/logo-256.png';
import { SpinningSource } from './SpinningSource';
import { useStyles } from './styles';

const outerCircleProps = {
  animationDuration: 30,
  height: 1300,
  width: 1300,
};

const innerCircleProps = {
  animationDuration: 26,
  height: 900,
  inverted: true,
  width: 900,
};

const smallerCircleProps = {
  animationDuration: 18,
  height: 500,
  width: 500,
};

/**
 * Renders the background circles and the moving sources
 */
const SourcesBackroundNM: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.circleHolder}>
        <SpinningSource
          logo={driveLogo}
          startRotation={0}
          withBorder
          {...{ ...outerCircleProps }}
        />
        <SpinningSource logo={gmailLogo} startRotation={60} {...{ ...outerCircleProps }} />
        <SpinningSource logo={outlookLogo} startRotation={120} {...{ ...outerCircleProps }} />
        <SpinningSource logo={trelloLogo} startRotation={180} {...{ ...outerCircleProps }} />
        <SpinningSource logo={driveLogo} startRotation={240} {...{ ...outerCircleProps }} />
        <SpinningSource logo={gmailLogo} startRotation={300} {...{ ...outerCircleProps }} />
      </div>

      <div className={classes.circleHolder}>
        <SpinningSource
          logo={onedriveLogo}
          startRotation={0}
          withBorder
          {...{ ...innerCircleProps }}
        />
        <SpinningSource logo={sharepointLogo} startRotation={60} {...{ ...innerCircleProps }} />
        <SpinningSource logo={slackLogo} startRotation={120} {...{ ...innerCircleProps }} />
        <SpinningSource logo={boxLogo} startRotation={180} {...{ ...innerCircleProps }} />
        <SpinningSource logo={onedriveLogo} startRotation={240} {...{ ...innerCircleProps }} />
        <SpinningSource logo={localDiskLogo} startRotation={300} {...{ ...innerCircleProps }} />
      </div>

      <div className={classes.circleHolder}>
        <SpinningSource
          logo={dropboxLogo}
          startRotation={0}
          withBorder
          {...{ ...smallerCircleProps }}
        />
        <SpinningSource logo={notionLogo} startRotation={60} {...{ ...smallerCircleProps }} />
        <SpinningSource logo={salesforceLogo} startRotation={120} {...{ ...smallerCircleProps }} />
        <SpinningSource logo={teamsLogo} startRotation={180} {...{ ...smallerCircleProps }} />
        <SpinningSource logo={o365Logo} startRotation={240} {...{ ...smallerCircleProps }} />
        <SpinningSource logo={networkDiskLogo} startRotation={300} {...{ ...smallerCircleProps }} />
      </div>
      <div className={classes.outmindLogoContainer}>
        <img alt="outmind-logo" className={classes.outmindLogo} src={outmindLogo} />
      </div>
    </div>
  );
};

export const SourcesBackround = memo(SourcesBackroundNM);
